import { UserProfileModel } from '@shared/models/user-profile.model';
import { IUserShort } from '@models/user';
import { UserType } from '@shared/models/user.model';

export function getUserType(userId: string | undefined): UserType {
  return userId ? 'users' : 'guests';
}

export function getUserTypeAndId(
  user: IUserShort | UserProfileModel
): [UserType, string] {
  let userId: string;
  const userType = getUserType(user.UserId);
  if (userType === 'users') {
    userId = user.UserId;
  } else {
    userId = user.UUID;
  }

  return [userType, userId];
}
